import { graphql } from 'gatsby';
import React from 'react';

import ContentBreadcrumb from '../components/content/ContentBreadcrumb/ContentBreadcrumb';
import ContentHeader from '../components/content/ContentHeader/ContentHeader';
import MemberList from '../components/content/MemberList/MemberList';
import Layout from '../components/layout/layout';
import Seo from '../components/layout/seo';

const MembersPage = ({ data, pageContext }) => {
    let {
        breadcrumb: { crumbs },
    } = pageContext;

    return (
        <Layout>
            <Seo title="Member Associations" />
            <div className="page__top">
                <ContentBreadcrumb
                    crumbs={crumbs}
                    label="Members"
                    banner={true}
                />
                <ContentHeader
                    title="Member Associations"
                    banner={data.banner}
                />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <MemberList members={data.members.edges} />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default MembersPage;

export const query = graphql`
    query {
        members: allNodeMemberAssociationPage(
            sort: { fields: [title], order: [ASC] }
            filter: { field_members_page: { eq: true } }
        ) {
            edges {
                node {
                    ...nodeMemberAssociationPageFragment
                }
            }
        }
        banner: file(relativePath: { eq: "banner-members.jpg" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
